import { useMemo, FC } from "react";
import * as T from "types";
import * as Ui from "semantic-ui-react";
import { Link } from "react-router-dom";
import { BucketLink, ConfluenceChannelsLink } from "components/BucketLink";

// add channels to the list here to fade them out in the channels overview
const oldChannels = [
  "alexm-dev",
  "axon",
  "axon-jordan",
  "axon-rc-bugfix-2022-03-30",
  "axon-rtbv4-production",
  "axon-sigcam2-beta",
  "axon-sigcam2-production",
  "axon-sigcam2-rc",
  "coredump-dev",
  "demo-vpn",
  "dev-few-packages",
  "development",
  "disable-services",
  "fausto-dev",
  "flasher-new",
  "flasher-old",
  "gdc-test",
  "jonathan-dev",
  "milestone-production",
  "norse-production",
  "panoramaStitching-dev",
  "provisioning",
  "remoteStreaming-beta",
  "remoteStreaming-dev",
  "remoteStreaming-production",
  "remoteStreaming-rc",
  "remoteStreaming-rtbv3-production",
  "roberto-dev",
  "rooftop-poc",
  "rooftop-proxyserver-beta",
  "rtb-dev",
  "rtb-production",
  "rtb-v2",
  "rtb-v4-production",
  "sigcam2-beta",
  "sigcam2-dev",
  "sigcam2-production",
  "sigcam2-rc",
  "simon-dev",
  "smoke-test",
  "srz-production",
  "srz-rtbv3-production",
  "test-packagemanager",
  "vlad-dev",
  "windsor-cm",
  "sofia-dev",
  "rtbv3-production",
  "axon-rtbv3-production",
  "alexh-dev",
  "p500-quality-dev",
  "p500-quality-beta",
  "produciton-rc",
  "odrt-dev",
  "odrt-preview",
  "p500-beta",
  "p500-dev",
  "p500-odrt-beta",
  "p500-preview",
  "p500-quality-rc",
  "p500-rc",
];

function isOldChannel(channel: string) {
  return oldChannels.includes(channel);
}

interface Props {
  allObjects: T.StorageMetadataList;
}

export const Channels: FC<Props> = (p) => {
  const filteredChannels = useMemo(() => {
    return T.groupChannels(p.allObjects).reduce((acc: string[], group) => {
      const first = group.first(null);
      if (!first) return acc;

      return [...acc, first.fkChannel];
    }, []);
  }, [p]);
  const activeChannels = useMemo(() => {
    return filteredChannels.filter((channel) => !isOldChannel(channel));
  }, [filteredChannels]);
  const archivedChannels = useMemo(() => {
    return filteredChannels.filter((channel) => isOldChannel(channel));
  }, [filteredChannels]);
  return (
    <div>
      <ConfluenceChannelsLink />
      <BucketLink />
      <Ui.Container style={{ marginTop: "20px" }}>
        <Ui.Header as="h2">Active Channels</Ui.Header>
        <Ui.Divider />
        {activeChannels.map((channel) => {
          return <ChannelItem key={channel} channel={channel} active={true} />;
        })}
      </Ui.Container>
      <Ui.Container style={{ marginTop: "20px" }}>
        <Ui.Header as="h2">Archived Channels</Ui.Header>
        <Ui.Divider />
        {archivedChannels.map((channel) => {
          return <ChannelItem key={channel} channel={channel} active={false} />;
        })}
      </Ui.Container>
    </div>
  );
};

interface IChannelProps {
  channel: string;
  active: boolean;
}
export const ChannelItem: FC<IChannelProps> = ({ channel, active }) => {
  return (
    <Ui.Item>
      <Ui.Item.Content>
        <Ui.Item.Header>
          <Link
            to={`/channel/${channel}`}
            style={{ color: !active ? "#a1b4c6" : "", fontSize: "1.3em" }}
          >
            {channel}
          </Link>
        </Ui.Item.Header>
      </Ui.Item.Content>
    </Ui.Item>
  );
};
